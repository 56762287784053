.project-select[data-v-be66b6f2] {
  width: 280px;
}
.toolbar[data-v-be66b6f2] {
  display: flex;
  flex-direction: var(--be66b6f2-flexDirection);
  justify-content: space-between;
}
.toolbar[data-v-be66b6f2] .input {
  margin-right: 8px;
  margin-bottom: var(--be66b6f2-margin);
}
